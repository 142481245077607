body {
  padding: 0 !important;
}

.btn {
  margin: 2px;
}

.marker_text {
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-weight: bold
}

.leaflet-marker-pane img {cursor: pointer !important}

.react-datepicker__time-list {
  padding: 0px
}

.jumbotron {
  margin: 0px auto;
  max-width: 600px;
  z-index: 20;
  position: relative;
  opacity: 0.8;
  background-color: #bbb !important;
}

.btn:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.navbar {
  min-height: 76px;
}

.navbar-nav {
  width: 100%;
}

.navbar-brand {
  padding: 0 !important;
}

.navbar-brand img {
  height: 40px;
}

.nav-item {
  padding: 20px 10px 0 10px;
}
@media (min-width: 768px) {
  .navbar-nav li:last-child {
    margin-left: auto;
  }
}

.leaflet-container {
  height: calc(100% - 38px);
  position: absolute;
  width: 100%;
}

.word-wrap-normal {
  word-wrap: normal;
}

.react-datepicker-popper {
  z-index: 2000 !important;
}

.react-datepicker-wrapper input {
    padding: 5px
}

.row_right {
  text-align: right;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range {
  background-color: hsla(336, 100%, 50%, 0.158) !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #ffebf3 !important;
}

.checkbox {
  width: 38px !important;
}

.switch {
  width: 40px !important;
  padding: 0 10px !important;
  border: 0 !important;
  vertical-align: middle;
}

.card-header .refresh {
  float: right;
}

.sub-events .row,
.competitors .row,
.controls .row {
  padding: 5px;
  word-wrap: none;
  align-items: center;
}

.competitors .row:not(:last-child) {
  border-bottom: 2px solid #00000020;
}

.entries .row.header {
  border-bottom: 1px solid gray;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
}

.entries .row:nth-child(odd),
.competitors .row:nth-child(odd) {
  background-color:#00000010;
}

.entries .row .form-control,
.sub-events .row .form-group,
.competitors .row .form-group {
  margin-bottom: 4px;
}

.competitors .row div[class*='col-'], 
.controls .row div[class*='col-'] {
  padding-left: 5px;
  padding-right: 5px;
  word-wrap: none;
}

.competitors .row .form-control div {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.users .row {
  padding: 5px;
}

.users .no-gutters {
  margin-right: 5px;
  margin-left: 5px;
}

.users .no-gutters > .col, .users .no-gutters > [class*="col-"] {
  padding-right: 5px;
  padding-left: px;
}

.users .row .form-control div {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.sub-events .header,
.controls .header {
  font-weight: bold;
}

.editable-setting {
  padding: 2px 0;
  cursor: pointer;
  position: relative;
  -webkit-transition: background 0.1s linear;
  transition: background 0.1s linear;
  line-height: 40px;
}

.editable-setting:hover, .editable-setting.editing {
  background: #f7f7fa;
}

.editable-setting .form-group {
  margin-bottom: 0;
  display: inline-block;
  width: 35%;
  min-width: 150px;
  max-width: 200px;
  padding: 2px;
}

.editable-setting:hover .edit-btn {
  display: inline;
}

.edit-btn {
  display: none;
}

.competitor-user-icon {
  margin: 3px 5px;
}

.competitor-user-icon-button {
  cursor: pointer;
  margin: 10px 0px 10px 5px;;
}

.icon-primary {
  color: #ff0066;
}