.ReactModal__Overlay {
  z-index: 2000;
}

.app-header {
  position: fixed;
  z-index: 1001;
  width: 100%;
}

.toast-notification {
  z-index: 2001 !important;
}

.app-body {
  position: absolute;
  top: 76px;
  width: 100%;
  background-color: #e4e5e6;
  min-height: calc(100vh - 76px);
}

.collapsed_sidebar {
  display: none !important;
}

.main {
  margin-left: 200px;
}

.main .container-fluid {
  padding: 30px 15px;
}
.main .container-fluid > .row {
  padding-bottom: 15px;
}
.collapsed_sidebar.sidebar ~ .main {
  margin-left: 0px;
}

@media (max-width: 768px) {
  .main {
    margin-left: 0px;
  }
}

.nav_accont {
  cursor: pointer;
}

.sidebar {
  position: fixed;
  top: 76px;
  bottom: 0;
  left: 0;
  width: 200px;
  display: block;
  z-index: 1;
  color: #fff;
  font-weight: 200;
  background-size: cover;
  background-position: center center; }
  .sidebar .sidebar-wrapper {
    position: relative;
    height: calc(100vh - 75px);
    overflow: auto;
    width: 200px;
    z-index: 4;
    padding-bottom: 30px; }
  .sidebar .nav {
    width: 200px;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0;}
    .sidebar .nav-item {
      position: relative;
      margin: 0;
    }
    .sidebar .nav .nav-item:hover>.nav-link{
      background:#ff0066}
    .sidebar .nav-link {
      display: block;
      padding: .75rem 1rem;
      color: #fff;
      text-decoration: none;
      background: 0 0;}
      .sidebar .nav-link svg {
        margin-right: 10px;
      }
    .sidebar .nav-title {
        padding: .75rem 1rem;
        font-size: 80%;
        font-weight: 700;
        color: #e4e7ea;
        text-transform: uppercase;
    }
    .sidebar .nav li.open > a,
    .sidebar .nav li.open > a:focus,
    .sidebar .nav li.open > a:hover {
      background: rgba(255, 255, 255, 0.13);
      opacity: 1; }
    .sidebar .nav li.active > a {
      color: #FFFFFF;
      opacity: 1;
      background: rgba(255, 255, 255, 0.23); }
    .sidebar .nav p {
      margin: 0;
      line-height: 30px;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase; }
    .sidebar .nav .caret {
      top: 24px;
      position: absolute;
      right: 15px; }
    .sidebar .nav i {
      font-size: 28px;
      float: left;
      margin-right: 15px;
      line-height: 30px;
      width: 30px;
      text-align: center; }

.sidebar .logo,
body > .navbar-collapse .logo {
  padding: 10px 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
  .sidebar .logo p,
  body > .navbar-collapse .logo p {
    float: left;
    font-size: 20px;
    margin: 10px 10px;
    color: #FFFFFF;
    line-height: 20px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; }
  .sidebar .logo .simple-text,
  body > .navbar-collapse .logo .simple-text {
    text-transform: uppercase;
    padding: 5px 0px;
    display: block;
    font-size: 18px;
    color: #FFFFFF;
    font-weight: 400;
    line-height: 30px; }

.sidebar .logo-tim,
body > .navbar-collapse .logo-tim {
  border-radius: 50%;
  border: 1px solid #333;
  display: block;
  height: 61px;
  width: 61px;
  float: left;
  overflow: hidden; }
  .sidebar .logo-tim img,
  body > .navbar-collapse .logo-tim img {
    width: 60px;
    height: 60px; }

.sidebar:after, .sidebar:before,
body > .navbar-collapse:after,
body > .navbar-collapse:before {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2; }

.sidebar:before,
body > .navbar-collapse:before {
  opacity: .33;
  background: #000000; }

.sidebar:after {
  background: #2f353a;
  z-index: 3;
  opacity: 1; }

.sidebar[data-image]:after, .sidebar.has-image:after,
body > .navbar-collapse[data-image]:after,
body > .navbar-collapse.has-image:after {
  opacity: .77; }

.sidebar[data-color="blue"]:after,
body > .navbar-collapse[data-color="blue"]:after {
  background: #1b8dff;
  background: -moz-linear-gradient(top, #1b8dff 0%, #2632c1 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #1b8dff), color-stop(100%, #2632c1));
  background: -webkit-linear-gradient(top, #1b8dff 0%, #2632c1 100%);
  background: -o-linear-gradient(top, #1b8dff 0%, #2632c1 100%);
  background: -ms-linear-gradient(top, #1b8dff 0%, #2632c1 100%);
  background: linear-gradient(to bottom, #1b8dff 0%, #2632c1 100%);
  background-size: 150% 150%; }

.sidebar[data-color="azure"]:after,
body > .navbar-collapse[data-color="azure"]:after {
  background: #1DC7EA;
  background: -moz-linear-gradient(top, #1DC7EA 0%, #4091ff 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #1DC7EA), color-stop(100%, #4091ff));
  background: -webkit-linear-gradient(top, #1DC7EA 0%, #4091ff 100%);
  background: -o-linear-gradient(top, #1DC7EA 0%, #4091ff 100%);
  background: -ms-linear-gradient(top, #1DC7EA 0%, #4091ff 100%);
  background: linear-gradient(to bottom, #1DC7EA 0%, #4091ff 100%);
  background-size: 150% 150%; }

.sidebar[data-color="green"]:after,
body > .navbar-collapse[data-color="green"]:after {
  background: #87CB16;
  background: -moz-linear-gradient(top, #87CB16 0%, #6dc030 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #87CB16), color-stop(100%, #6dc030));
  background: -webkit-linear-gradient(top, #87CB16 0%, #6dc030 100%);
  background: -o-linear-gradient(top, #87CB16 0%, #6dc030 100%);
  background: -ms-linear-gradient(top, #87CB16 0%, #6dc030 100%);
  background: linear-gradient(to bottom, #87CB16 0%, #6dc030 100%);
  background-size: 150% 150%; }

.sidebar[data-color="orange"]:after,
body > .navbar-collapse[data-color="orange"]:after {
  background: #FFA534;
  background: -moz-linear-gradient(top, #FFA534 0%, #ff5221 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFA534), color-stop(100%, #ff5221));
  background: -webkit-linear-gradient(top, #FFA534 0%, #ff5221 100%);
  background: -o-linear-gradient(top, #FFA534 0%, #ff5221 100%);
  background: -ms-linear-gradient(top, #FFA534 0%, #ff5221 100%);
  background: linear-gradient(to bottom, #FFA534 0%, #ff5221 100%);
  background-size: 150% 150%; }

.sidebar[data-color="red"]:after,
body > .navbar-collapse[data-color="red"]:after {
  background: #FB404B;
  background: -moz-linear-gradient(top, #FB404B 0%, #bb0502 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FB404B), color-stop(100%, #bb0502));
  background: -webkit-linear-gradient(top, #FB404B 0%, #bb0502 100%);
  background: -o-linear-gradient(top, #FB404B 0%, #bb0502 100%);
  background: -ms-linear-gradient(top, #FB404B 0%, #bb0502 100%);
  background: linear-gradient(to bottom, #FB404B 0%, #bb0502 100%);
  background-size: 150% 150%; }

.sidebar[data-color="purple"]:after,
body > .navbar-collapse[data-color="purple"]:after {
  background: #9368E9;
  background: -moz-linear-gradient(top, #9368E9 0%, #943bea 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #9368E9), color-stop(100%, #943bea));
  background: -webkit-linear-gradient(top, #9368E9 0%, #943bea 100%);
  background: -o-linear-gradient(top, #9368E9 0%, #943bea 100%);
  background: -ms-linear-gradient(top, #9368E9 0%, #943bea 100%);
  background: linear-gradient(to bottom, #9368E9 0%, #943bea 100%);
  background-size: 150% 150%; }

.main-panel {
  background: rgba(203, 203, 210, 0.15);
  position: relative;
  float: right;
  width: calc(100% - 260px); }
  .main-panel > .content {
    padding: 30px 15px;
    min-height: calc(100% - 123px); }
  .main-panel > .footer {
    border-top: 1px solid #e7e7e7; }
  .main-panel .navbar {
    margin-bottom: 0; }

.sidebar,
.main-panel {
  -webkit-transition-property: top,bottom;
  transition-property: top,bottom;
  -webkit-transition-duration: .2s,.2s;
  transition-duration: .2s,.2s;
  -webkit-transition-timing-function: linear,linear;
  transition-timing-function: linear,linear;
  -webkit-overflow-scrolling: touch; }
